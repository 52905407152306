import { Illustration, ILLUSTRATIONS } from '#components/Image'
import { useCtas } from '#hooks'
import {
  clickUpgradeAtSidebarTaskEvent,
  viewUpgradeAtSidebarTaskEvent,
} from '#services/events'
import { Check } from '@phosphor-icons/react'
import { Button, Modal } from '@wordpress/components'
import { Trans as T } from 'react-i18next'

import '#components/UpgradeModal/styles.css'

export const UpgradeModal = ({ closeModal }) => {
  const plan = 'PRO'

  const { data: availableCtas } = useCtas()

  const upgradeCta = availableCtas?.find(
    (cta) => cta.id === 'wordpress-upgrade',
  )

  React.useEffect(() => {
    viewUpgradeAtSidebarTaskEvent()
  }, [])

  const clickAndRedirect = () => {
    clickUpgradeAtSidebarTaskEvent()
    window.open(upgradeCta?.attributes.targetUrl, '_blank')
  }

  return (
    <Modal className="mktseo mktseo-modal--black" onRequestClose={closeModal}>
      <section className={'upgrade-section-panel editor'}>
        <article className="section-content">
          <figure>
            <span className="ola_tag">
              <T i18nKey="upgrade.tag.title" values={{ plan }} />
            </span>
            <Illustration name={ILLUSTRATIONS.UPGRADE.OPTIMIZE} />
          </figure>
          <header>
            <h3 className="title">
              <T i18nKey="upgrade.modal.title.editor" />
            </h3>
            <p className="description">
              <T i18nKey="upgrade.modal.description.editor" values={{ plan }} />
            </p>
          </header>
        </article>

        <article className="section-features">
          <h3 className="title">
            <T i18nKey="upgrade.modal.sidebar.title" values={{ plan }} />
          </h3>
          <p className="description">
            <T i18nKey="upgrade.modal.sidebar.description" />
          </p>
          <ul>
            {Array.from({ length: 5 }).map((_, i) => (
              <li key={i}>
                <Check size={24} />
                <p>
                  <T i18nKey={`upgrade.modal.features.${i + 1}`} />
                </p>
              </li>
            ))}
          </ul>
          <Button className="upgrade-btn" onClick={clickAndRedirect}>
            <T i18nKey="g.upgrade" />
          </Button>
        </article>
      </section>
    </Modal>
  )
}

import { ILLUSTRATIONS, Illustration, Show, Tooltip } from '#components'
import { useCtas, useMeta } from '#hooks'
import {
  clickUpgradeAtSidebarAdvancedEvent,
  viewSidebarTabAdvancedEvent,
  viewUpgradeAtSidebarAdvancedEvent,
} from '#services/events'
import { sendMetaTags } from '#services/metaTags'
import {
  Button,
  ButtonGroup,
  Flex,
  FormToggle,
  Icon,
  Modal,
  PanelBody,
  Spinner,
  TextControl,
} from '@wordpress/components'
import { useEffect, useState } from '@wordpress/element'
import { helpFilled } from '@wordpress/icons'
import { useController, useForm } from 'react-hook-form'
import { Trans as T, useTranslation } from 'react-i18next'

import './styles.css'

const PanelBodyWithIcon = ({ title, children, tooltip }) => (
  <PanelBody
    title={
      <>
        {title}
        <Tooltip height={18} text={tooltip}>
          <Icon className="advanced__icon" size={18} icon={helpFilled} />
        </Tooltip>
      </>
    }
  >
    {children}
  </PanelBody>
)

const Switch = ({ children }) => (
  <Flex justify="flex-start">
    <span>
      <T i18nKey="g.no" />
    </span>
    {children}
    <span>
      <T i18nKey="g.yes" />
    </span>
  </Flex>
)

export const Advanced = ({ pop }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  const { data: availableCtas, isLoading: isLoadingCTA } = useCtas()
  const upgradeCta = availableCtas?.find(
    (cta) => cta.id === 'wordpress-upgrade',
  )

  const [isBusy, setIsBusy] = useState(false)

  const {
    data: noindex,
    isLoading: isLoadingNoindex,
    isValidating: isValidatingNoindex,
  } = useMeta({
    popId: pop.id,
    key: 'noindex',
  })

  const {
    data: redirection,
    isLoading: isLoadingRedirection,
    isValidating: isValidatingRedirection,
  } = useMeta({
    popId: pop.id,
    key: 'redirection',
  })

  const {
    data: canonical,
    isLoading: isLoadingCanonical,
    isValidating: isValidatingCanonical,
  } = useMeta({
    popId: pop.id,
    key: 'canonical',
  })

  const { control, handleSubmit, watch, setValue } = useForm({
    values: {
      noindex: Boolean(noindex),
      redirection: Boolean(redirection),
      redirectionUrl: redirection || '',
      canonical: Boolean(canonical),
      canonicalUrl: canonical || '',
    },
  })

  const { field: noindexField } = useController({
    name: 'noindex',
    control,
  })

  const { field: redirectionField } = useController({
    name: 'redirection',
    control,
  })

  const { field: redirectionUrlField } = useController({
    name: 'redirectionUrl',
    control,
    shouldUnregister: true,
    rules: { required: true },
  })

  const { field: canonicalField } = useController({
    name: 'canonical',
    control,
  })

  const { field: canonicalUrlField } = useController({
    name: 'canonicalUrl',
    control,
    shouldUnregister: true,
  })

  const onSubmit = (data) => {
    setIsBusy(true)
    Promise.all([
      sendMetaTags({
        postId: pop.id,
        data: {
          noindex: data.noindex,
        },
      }),
      sendMetaTags({
        postId: pop.id,
        data: {
          redirection: data.redirection ? data.redirectionUrl : '',
        },
      }),
      sendMetaTags({
        postId: pop.id,
        data: {
          canonical: data.canonical ? data.canonicalUrl : '',
        },
      }),
    ])
      .catch((e) => {
        console.error(e)
      })
      .finally(() => setIsBusy(false))
  }

  const restoreRedirectionUrl = () => {
    setValue('redirection', true)
    setShowModal(false)
  }

  const emptyRedirectionUrl = () => {
    setValue('redirectionUrl', '')
    setShowModal(false)
  }

  const navigateToUpgrade = () => {
    clickUpgradeAtSidebarAdvancedEvent()
    window.open(upgradeCta?.attributes.targetUrl, '_blank')
  }

  useEffect(() => {
    if (!watch('redirection') && watch('redirectionUrl') !== '') {
      setShowModal(true)
    }
  }, [watch('redirection')])

  useEffect(() => {
    viewSidebarTabAdvancedEvent()

    if (upgradeCta) {
      viewUpgradeAtSidebarAdvancedEvent()
    }
  }, [])

  return upgradeCta ? (
    <div className="advanced-lite">
      <Illustration name={ILLUSTRATIONS.UPGRADE.ROCKET} />
      <b>
        <p>{t('upgrade.panel.title')}</p>
      </b>
      <Button
        className="upgrade-btn"
        isBusy={isBusy}
        disabled={isBusy}
        onClick={navigateToUpgrade}
        variant="primary"
      >
        {t('g.upgrade')}
      </Button>
    </div>
  ) : (
    <>
      <form className="advanced" onSubmit={handleSubmit(onSubmit)}>
        <PanelBodyWithIcon
          title={t('sidebar.advanced.indexation.title')}
          tooltip={
            <T
              i18nKey="sidebar.advanced.indexation.popup"
              components={[<b />]}
            />
          }
        >
          <p>{t('sidebar.advanced.indexation.text')}</p>
          {isLoadingNoindex || isValidatingNoindex ? (
            <Spinner />
          ) : (
            <Switch>
              <FormToggle {...noindexField} checked={noindexField.value} />
            </Switch>
          )}
        </PanelBodyWithIcon>

        <PanelBodyWithIcon
          title={t('sidebar.advanced.redirection.title')}
          tooltip={
            <T
              i18nKey="sidebar.advanced.redirection.popup"
              components={[<b />]}
            />
          }
        >
          <p>{t('sidebar.advanced.redirection.text')}</p>
          {isLoadingRedirection || isValidatingRedirection ? (
            <Spinner />
          ) : (
            <Switch>
              <FormToggle
                {...redirectionField}
                checked={redirectionField.value}
              />
            </Switch>
          )}
          <Show when={watch('redirection')}>
            <TextControl
              type="url"
              {...redirectionUrlField}
              className="advanced__textfield"
              label={t('sidebar.advanced.redirection.label')}
              placeholder={t('sidebar.advanced.redirection.placeholder')}
            />
          </Show>
        </PanelBodyWithIcon>

        <PanelBodyWithIcon
          title={t('sidebar.advanced.canonical.title')}
          tooltip={
            <T
              i18nKey="sidebar.advanced.canonical.popup"
              components={[<b />]}
            />
          }
        >
          <p>{t('sidebar.advanced.canonical.text')}</p>
          {isLoadingCanonical || isValidatingCanonical ? (
            <Spinner />
          ) : (
            <Switch>
              <FormToggle {...canonicalField} checked={canonicalField.value} />
            </Switch>
          )}
          <Show when={watch('canonical')}>
            <TextControl
              {...canonicalUrlField}
              className="advanced__textfield"
              label={t('sidebar.advanced.canonical.label')}
              placeholder={t('sidebar.advanced.canonical.placeholder')}
            />
          </Show>
        </PanelBodyWithIcon>
        <Button
          isBusy={isBusy}
          disabled={isBusy}
          variant="primary"
          type="submit"
        >
          {t('g.save')}
        </Button>
      </form>
      <Show when={showModal}>
        <Modal
          className="mktseo"
          title={t('sidebar.advanced.redirection.modal.title')}
          onRequestClose={restoreRedirectionUrl}
        >
          <p>{t('sidebar.advanced.redirection.modal.text')}</p>
          <ButtonGroup>
            <Flex gap="5" justify="end">
              <Button
                isDestructive
                variant="secondary"
                onClick={restoreRedirectionUrl}
              >
                {t('g.cancel')}
              </Button>
              <Button
                isDestructive
                variant="primary"
                onRequestClose={restoreRedirectionUrl}
                onClick={emptyRedirectionUrl}
              >
                {t('sidebar.advanced.redirection.modal.button')}
              </Button>
            </Flex>
          </ButtonGroup>
        </Modal>
      </Show>
    </>
  )
}

import { SendingEventError } from '#errors'
import { endpoints, request } from '#services'

export const eventNames = {
  USAGE: 'usage',
}

const placements = {
  SIDEBAR_TAB_TASK: 'sidebar.tab.task',
  SIDEBAR_TAB_SCHEMA: 'sidebar.tab.schema',
  SIDEBAR_TAB_ADVANCED: 'sidebar.tab.advanced',
  EDITOR: 'editor',
}

const elements = {
  AUTO_OPTIMIZE_CTA: 'auto.optimize.cta',
  UPGRADE_CTA: 'upgrade.cta',
  LAUNCH_BUTTON: 'launch.button',
}

const createEvent = async (action, event, placement, element = null) => {
  try {
    return await request(
      endpoints.events.create({
        action,
        event: `wordpress.${event}`,
        placement,
        ...(element && { element }),
      }),
    )
  } catch (error) {
    throw new SendingEventError(error)
  }
}

const eventGenerator =
  (action, element = null) =>
  (placement) =>
  async () =>
    createEvent(action, eventNames.USAGE, placement, element)

export const viewEvent = (element = null) => eventGenerator('view', element)
export const viewSidebarTabTaskEvent = viewEvent()(placements.SIDEBAR_TAB_TASK)
export const viewSidebarTabSchemaEvent = viewEvent()(
  placements.SIDEBAR_TAB_SCHEMA,
)
export const viewSidebarTabAdvancedEvent = viewEvent()(
  placements.SIDEBAR_TAB_ADVANCED,
)
export const viewUpgradeAtSidebarAdvancedEvent = viewEvent(
  elements.UPGRADE_CTA,
)(placements.SIDEBAR_TAB_ADVANCED)

export const viewUpgradeAtSidebarTaskEvent = viewEvent(elements.UPGRADE_CTA)(
  placements.SIDEBAR_TAB_TASK,
)

export const clickEvent = (element) => eventGenerator('click', element)

export const clickAutoOptimizeAtSidebarTaskEvent = clickEvent(
  elements.AUTO_OPTIMIZE_CTA,
)(placements.SIDEBAR_TAB_TASK)

export const clickUpgradeAtSidebarAdvancedEvent = clickEvent(
  elements.UPGRADE_CTA,
)(placements.SIDEBAR_TAB_ADVANCED)

export const clickEditorLaunchButtonEvent = clickEvent(elements.LAUNCH_BUTTON)(
  placements.EDITOR,
)

export const clickUpgradeAtSidebarTaskEvent = clickEvent(elements.UPGRADE_CTA)(
  placements.SIDEBAR_TAB_TASK,
)
